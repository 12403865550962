import _ from 'lodash';

const messages = {
  'required' : '필수 질문입니다.',
  'telephone' : '숫자와 - 만 입력 가능합니다.',
  'email' : 'Please enter a valid email address.',
  'currency' : 'Invalid characters entered, numbers only.',
  'passwordCheck' : 'password must contain at least eight characters, at least one number and letters.',
  'passwordConfirm' : 'The passwords do not match.',
  'minLength' : 'Please enter at least {0} characters.'
};

const ErrorMessageFeedback = ({error, placeholder}) => {
  if(_.isNil(error)) {
    return <></>;
  }

  let msg = messages[error.type];
  if(error.type === 'minLength') {
    msg = msg.replace('{0}', placeholder[error.type]);
  }
  return (
    <>
      <div className="invalid-feedback"><span className="material-icons">warning_amber</span>{ msg }</div>
    </>
  );
};

export default ErrorMessageFeedback;