
function QuestionType100({ item }) {

  return (
    <>
      <div className="content-block type-100">
        <div>{item.text}</div>
      </div>
    </>
  );
}

export default QuestionType100;