import React from "react";
import { Outlet } from 'react-router-dom';
import Header from './Header'

// Layout
const DefaultLayout = () => {
  return (
    <>
      <div className="d-flex align-items-stretch">
        <div id="content-wrapper">
          <Header />

          <div className="content col-12 col-md-8 col-lg-6">
            <Outlet />
          </div>

          {/*<Footer />*/}
        </div>
      </div>
    </>
  );
};
export default DefaultLayout;

