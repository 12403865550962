import _ from 'lodash';

function isEmpty(value) {
  return _.isNil(value) || value.length === 0;
}

export const validateTelephone = (value) => {
  return isEmpty(value) || !/[^0123456789-]/g.test(value);
}

