import axios from 'axios';
import qs from "qs";
import { toast } from 'react-toastify';
import _ from 'lodash';
import { store } from "../store/store";
import { loaderSlice } from "../store/LoaderSlice";
import { myHistory } from "../router/History";
import {loginInfoSlice} from "../store/LoginInfoSlice";

const httpConnector = axios.create({
  baseURL : process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: { 'X-Requested-With': 'json' },
  paramsSerializer: params => {
    return qs.stringify(params);
  }
});
httpConnector.isShowLoadingBar = true;

httpConnector.interceptors.request.use(function (request) {
  showLoadingBar();
  return request;
}, null);

httpConnector.interceptors.response.use(
(response) => {
  hideLoadingBar();
  // 로그인 처리
  const loginResult = loginHandler(response);

  return loginResult ? response : Promise.reject(response.data.loginResult.message);
}, (error) => {
  hideLoadingBar();
  errorHandler(error);

  return Promise.reject(error);
});

function loginHandler(response) {

    if (!response || !response.data || !response.data.loginResult) {
        return true;
    }
    const result = response.data.loginResult;
    if(!result || result.loginAction) {
      return true;
    }

    if (result.message) {
      store.dispatch(loginInfoSlice.actions.logout());
      myHistory.replace('/adms/login/view');
      return false;
    }

    return true;
}

function showLoadingBar() {
  store.dispatch(loaderSlice.actions.increment());
}
function hideLoadingBar() {
  store.dispatch(loaderSlice.actions.decrement());
}
function errorHandler(error) {
  if(error.response.status === 404) {
    myHistory.replace('/404');
    return;
  }
  const res = error.response?.data;
  if(_.isNil(res) || res.message === '') {
    toast.error('An error has occurred.');
    return;
  }

  toast.error(
    <>
      {res.message}
      {
        (res.fieldErrors || []).map((item, idx) =>
          <div key={idx}>
            <span>{`${item.fieldName}  ${item.message}` }</span>
          </div>
        )
      }
    </>
  );
}

const get = httpConnector.get;
const post = httpConnector.post;
const put = httpConnector.put;
const deleteMethod = httpConnector.delete;

export { get, post, put, deleteMethod as delete };
