
export const questions = [
  {
    questionType: 100,
    text: 'I. 단어를 읽고 알맞은 그림을 하나만 고르세요.'
  },
  {
    questionType: 1,
    questionNo: 1,
    question: '1. restroom',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-1-1.png'},
      {imgPath: '/step2/reading/question-1-2.png'},
      {imgPath: '/step2/reading/question-1-3.png'},
      {imgPath: '/step2/reading/question-1-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 2,
    question: '2. bridge',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-2-1.png'},
      {imgPath: '/step2/reading/question-2-2.png'},
      {imgPath: '/step2/reading/question-2-3.png'},
      {imgPath: '/step2/reading/question-2-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 3,
    question: '3. vegetable',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-3-1.png'},
      {imgPath: '/step2/reading/question-3-2.png'},
      {imgPath: '/step2/reading/question-3-3.png'},
      {imgPath: '/step2/reading/question-3-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 4,
    question: '4. return',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-4-1.png'},
      {imgPath: '/step2/reading/question-4-2.png'},
      {imgPath: '/step2/reading/question-4-3.png'},
      {imgPath: '/step2/reading/question-4-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 5,
    question: '5. student',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-5-1.png'},
      {imgPath: '/step2/reading/question-5-2.png'},
      {imgPath: '/step2/reading/question-5-3.png'},
      {imgPath: '/step2/reading/question-5-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 6,
    question: '6. hate',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-6-1.png'},
      {imgPath: '/step2/reading/question-6-2.png'},
      {imgPath: '/step2/reading/question-6-3.png'},
      {imgPath: '/step2/reading/question-6-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 7,
    question: '7. lazy',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-7-1.png'},
      {imgPath: '/step2/reading/question-7-2.png'},
      {imgPath: '/step2/reading/question-7-3.png'},
      {imgPath: '/step2/reading/question-7-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 8,
    question: '8. listen',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-8-1.png'},
      {imgPath: '/step2/reading/question-8-2.png'},
      {imgPath: '/step2/reading/question-8-3.png'},
      {imgPath: '/step2/reading/question-8-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 9,
    question: '9. playground',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-9-1.png'},
      {imgPath: '/step2/reading/question-9-2.png'},
      {imgPath: '/step2/reading/question-9-3.png'},
      {imgPath: '/step2/reading/question-9-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 10,
    question: '10. clean',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-10-1.png'},
      {imgPath: '/step2/reading/question-10-2.png'},
      {imgPath: '/step2/reading/question-10-3.png'},
      {imgPath: '/step2/reading/question-10-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 11,
    question: '11. introduce',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-11-1.png'},
      {imgPath: '/step2/reading/question-11-2.png'},
      {imgPath: '/step2/reading/question-11-3.png'},
      {imgPath: '/step2/reading/question-11-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 12,
    question: '12. walk',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-12-1.png'},
      {imgPath: '/step2/reading/question-12-2.png'},
      {imgPath: '/step2/reading/question-12-3.png'},
      {imgPath: '/step2/reading/question-12-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 13,
    question: '13. difficult',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-13-1.png'},
      {imgPath: '/step2/reading/question-13-2.png'},
      {imgPath: '/step2/reading/question-13-3.png'},
      {imgPath: '/step2/reading/question-13-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 14,
    question: '14. curious',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-14-1.png'},
      {imgPath: '/step2/reading/question-14-2.png'},
      {imgPath: '/step2/reading/question-14-3.png'},
      {imgPath: '/step2/reading/question-14-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 15,
    question: '15. wash',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-15-1.png'},
      {imgPath: '/step2/reading/question-15-2.png'},
      {imgPath: '/step2/reading/question-15-3.png'},
      {imgPath: '/step2/reading/question-15-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 16,
    question: '16. dish',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-16-1.png'},
      {imgPath: '/step2/reading/question-16-2.png'},
      {imgPath: '/step2/reading/question-16-3.png'},
      {imgPath: '/step2/reading/question-16-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 17,
    question: '17. bake',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-17-1.png'},
      {imgPath: '/step2/reading/question-17-2.png'},
      {imgPath: '/step2/reading/question-17-3.png'},
      {imgPath: '/step2/reading/question-17-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 18,
    question: '18. borrow',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-18-1.png'},
      {imgPath: '/step2/reading/question-18-2.png'},
      {imgPath: '/step2/reading/question-18-3.png'},
      {imgPath: '/step2/reading/question-18-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 19,
    question: '19. choose',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-19-1.png'},
      {imgPath: '/step2/reading/question-19-2.png'},
      {imgPath: '/step2/reading/question-19-3.png'},
      {imgPath: '/step2/reading/question-19-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 20,
    question: '20. famous',
    isRequired: true,
    answers : [
      {imgPath: '/step2/reading/question-20-1.png'},
      {imgPath: '/step2/reading/question-20-2.png'},
      {imgPath: '/step2/reading/question-20-3.png'},
      {imgPath: '/step2/reading/question-20-4.png'},
    ]
  },
  {
    questionType: 100,
    text: 'II. 문제를 읽고 그림과 맞는 내용에 체크를 해주세요.'
  },
  {
    questionType: 3,
    questionNo: 21,
    question: '문제를 읽고 그림과 맞는 내용에는 O를, 틀린 내용에는 X를 체크를 해주세요.',
    isRequired: true,
    imagePath: '/step2/reading/question-21-1.png',
    answers : [
      { text: '1. The man is playing the piano.', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
      { text: '2. Some of the children are dancing.', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
      { text: '3. The cat is sleeping under a chair.', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
      { text: '4. The woman is holding a balloon.', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
      { text: '5. There is a lamp on the bookcase.', answerKinds: [ {text: 'O'}, {text: 'X'} ] },

    ]
  },
  {
    questionType: 3,
    questionNo: 22,
    question: '문제를 읽고 그림과 맞는 내용에는 O를, 틀린 내용에는 X를 체크를 해주세요.',
    isRequired: true,
    imagePath: '/step2/reading/question-22-1.png',
    answers : [
      { text: '1. There\'s an old boot in the water.', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
      { text: '2. You can see three fish in the picture.', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
      { text: '3. The children are fishing in the sea.', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
      { text: '4. There’s a green frog on the bag.', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
      { text: '5. The dog is curiously looking at the sky.', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
    ]
  },
  {
    questionType: 100,
    text: 'III. 주어진 대화를 읽고 적절한 답을 하나만 고르세요.'
  },
  {
    questionType: 2,
    questionNo: 23,
    question: '1. Which is your favorite sport to watch?',
    isRequired: true,
    answers : [
      { text: 'It’s my favorite' },
      { text: 'Baseball.' },
      { text: 'I like it best.' },
    ]
  },
  {
    questionType: 2,
    questionNo: 24,
    question: '2. Did you see the movie about firefighters?',
    isRequired: true,
    answers : [
      { text: 'Yes, that’s him.' },
      { text: 'Yes, so do I.' },
      { text: 'Yes, it was fun.' },
    ]
  },
  {
    questionType: 2,
    questionNo: 25,
    question: '3. Is John the one with black hair?',
    isRequired: true,
    answers : [
      { text: 'Yes, that’s right.' },
      { text: 'No, he hasn’t.' },
      { text: 'He’s got one.' },
    ]
  },
  {
    questionType: 2,
    questionNo: 26,
    question: '4. What do you want to buy today?',
    isRequired: true,
    answers : [
      { text: 'I went to get it.' },
      { text: 'Something for my mom’s birthday.' },
      { text: 'There were lots of presents.' },
    ]
  },
  {
    questionType: 2,
    questionNo: 27,
    question: '5. Let’s go to the zoo this weekend.',
    isRequired: true,
    answers : [
      { text: 'Ok, he can ask us.' },
      { text: 'Ok, that’s Fred’s.' },
      { text: 'Ok, that sounds good.' },
    ]
  },
  {
    questionType: 100,
    text: 'VI. 짧은 글을 읽고 각 빈칸에 들어갈 알맞은 단어를 예시에서 고르세요.'
  },
  {
    questionType: 4,
    questionNo: 28,
    question: '1. 짧은 글을 읽고 각 빈칸에 들어갈 알맞은 단어를 예시에서 고르세요.',
    isRequired: false,
    imagePath: '/step2/reading/question-28-1.png',
    answers : [
      { text: '1. Lots of lizards are very small but some are really ______________ .', isRequired: true, answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'} ] },
      { text: '2. Many lizards are green, grey, or, yellow. Some like eating ______________ and some like eating fruit.', isRequired: true, answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'} ] },
      { text: '3. A lizard can run on its four ______________', isRequired: true, answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'} ] },
      { text: '4. And it has a long ______________ at the end of its body.', isRequired: true, answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'} ] },
      { text: '5. You can find some lizards on the ______________  at the beach. Lizards love sleeping in the sun!', isRequired: true, answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'} ] },
    ]
  },
  {
    questionType: 4,
    questionNo: 29,
    question: '2. 짧은 글을 읽고 각 빈칸에 들어갈 알맞은 단어를 예시에서 고르세요.',
    isRequired: false,
    imagePath: '/step2/reading/question-29-1.png',
    answers : [
      { text: '1. David\'s family is going to move to a new house. But David was _____________ about leaving all his friends. Last Tuesday, David\'s family drove to their new house.', isRequired: true, answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'}, {text: '10'} ] },
      { text: '2. When they arrived at the house, David\'s dad gave him a _______. \'Go and open the front door with this, David,\' he said.', isRequired: true, answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'}, {text: '10'} ] },
      { text: '3. David went inside. The house was bigger than their ______ house and from a window, he could see some boys in a park.', isRequired: true, answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'}, {text: '10'} ] },
      { text: '4. He could see a _________ across a river.  \'I can play football in that park, and maybe I can fish in that river,\' he thought. There was no park or river near his old house.', isRequired: true, answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'}, {text: '10'} ] },
      { text: '5. David began to smile. \'I\'m ready. I\'m going to be _______ here!\'', isRequired: true, answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'}, {text: '10'} ] },
    ]
  },
];