import _ from 'lodash';
import alertify from 'alertifyjs';
import 'alertifyjs/build/css/alertify.css'
import 'alertifyjs/build/css/themes/default.css'

alertify.set('confirm', 'closable', false);
alertify.set('confirm', 'transition', 'pulse');
alertify.defaults.glossary.title = 'Message';

export function confirm (message, fnOk, fnCancel) {
  alertify.confirm('Confirm', message, function () {
    fnOk();
  }, function() {
    if(_.isFunction(fnCancel)) {
      fnCancel();
    }
  });
}