import {Controller} from "react-hook-form";
import {Form} from "react-bootstrap";
import ErrorMessageFeedback from "../../../components/common/ErrorMessageFeedback";
import ReactAudioPlayer from "react-audio-player";

function QuestionType2({ questionNo, item, control, errors }) {
  const radioName = `answer${questionNo}`;

  return (
    <>
      <div className="content-block">
        <div className={`question-item question-type2-item col-12 ${item.isRequired ? 'required' : ''}`}>
          <div className="title mb-3">{item.question}</div>
          {
            item.audioPath &&
            <div className="text-center mt-3 mb-3">
              <ReactAudioPlayer
                src={item.audioPath}
                autoPlay={false}
                controls
                className="w-70"
                controlsList={"nodownload nofullscreen noremoteplayback noplaybackrate"}
              />
            </div>
          }

          {
            item.imagePath &&
            <div className="questionImage">
              <img src={item.imagePath} alt={`question ${questionNo}`} />
            </div>
          }

          <Controller
            name={radioName}
            control={control}
            rules={ {required: item.isRequired} }
            render={({ field }) => {
              return (
                <Form.Group className="answers">
                  {
                    item.answers.map((answer, idx) => {
                      return (
                        <div className="col-6 mb-3" key={`${questionNo}${idx}`}>
                          <Form.Check
                            {...field}
                            type="radio"
                            name={radioName}
                            id={`awnser-${questionNo}-${idx}`}
                            label={answer.text}
                            value={idx + 1}
                            checked={parseInt(field.value) === idx + 1}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                        </div>
                      );
                    })
                  }
                </Form.Group>
              );
            }}
          />

          <ErrorMessageFeedback error={errors[radioName]} />
        </div>
      </div>
    </>
  );
}

export default QuestionType2;