import {Controller} from "react-hook-form";
import {Form} from "react-bootstrap";
import ReactAudioPlayer from "react-audio-player";

function QuestionType4({ questionNo, item, control, errors }) {
  return (
    <>
      <div className="content-block">
        <div className={`question-item question-type3-item col-12`}>
          <div className="title mb-3">{item.question}</div>
            <div className="questionImage">
              <img src={item.imagePath} alt={`question ${questionNo}`} />
            </div>
        </div>
      </div>
      {
        item.answers.map((answerItem, answerIdx) => {
          const radioName = `answer${questionNo}_${answerIdx + 1 }`;
          return (
            <div key={`${questionNo}_${answerIdx + 1 }`} className="content-block">
              {
                answerItem.text &&
                <div>{answerItem.text}</div>
              }

              {
                answerItem.audioPath &&
                <div className="text-center mt-3 mb-3">
                  <ReactAudioPlayer
                    src={answerItem.audioPath}
                    autoPlay={false}
                    controls
                    className="w-70"
                    controlsList={"nodownload nofullscreen noremoteplayback noplaybackrate"}
                  />
                </div>
              }

              <div className="answer-table mt-5">
                <div className="row">
                  {
                    answerItem.answerKinds.map((answerKind, answerKindIdx) => {
                      return (
                        <div key={`${questionNo}header${answerKindIdx}`} className="cell">{answerKind.text}</div>
                      );
                    })
                  }
                </div>
                <div className="row">
                  <Controller
                    name={radioName}
                    control={control}
                    rules={ {required: answerItem.isRequired} }
                    render={({ field }) => {
                      return (
                        answerItem.answerKinds.map((answer, idx) => {
                          return (
                            <div className="cell" key={`${questionNo}-${answerIdx}-${idx}`}>
                              <Form.Check
                                {...field}
                                type="radio"
                                name={radioName}
                                id={`awnser-${questionNo}-${idx}`}
                                label=""
                                value={idx + 1}
                                checked={parseInt(field.value) === idx + 1}
                                onChange={(e) => {
                                  field.onChange(e.target.value);
                                }}
                              />
                            </div>
                          );
                        })
                      );
                    }}
                  />
                </div>
              </div>

              {
                errors[radioName] &&
                <div className="invalid-feedback"><span className="material-icons">error_outline</span>이 질문에서는 행마다 하나의 응답을 작성해야 합니다.</div>
              }
            </div>
          );
        })
      }
    </>
  );
}

export default QuestionType4;