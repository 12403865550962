import Routers from './router/Routers';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { HistoryRouter } from "./router/HistoryRouter";
import { myHistory } from "./router/History";
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { store } from './store/store';
import { loaderSlice } from "./store/LoaderSlice";
import Loader from "./components/common/Loader";
import { ToastContainer } from 'react-toastify';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './assets/styles/style.scss';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 'infinity',
      cacheTime: false,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      suspense: false,
    }
  }
});

function App() {
  // 화면 refresh시 loaderCount 초기화
  store.dispatch(loaderSlice.actions.init());

  return (
      <>
        <HelmetProvider>
          <Helmet>
            <title>helloludi online test</title>
          </Helmet>

          <HistoryRouter history={myHistory}>
            <QueryClientProvider client={queryClient}>
              <Routers />
              {
                process.env.REACT_APP_QUERY_DEV_TOOLS === 'true' &&
                <ReactQueryDevtools initialIsOpen={false} />
              }
            </QueryClientProvider>
          </HistoryRouter>

          <Loader />

          <ToastContainer
            theme="colored"
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            style={{ width: "auto" }}
          />

        </HelmetProvider>
      </>
  );
}

export default App;
