import React from 'react';
import {Routes, Route } from 'react-router-dom';
import ScrollToTop from "../components/common/ScrollToTop";
import DefaultLayout from '../layouts/default';
import Page404 from '../pages/404';
import Main from '../pages/Main';
import ReadingStep1 from '../pages/onlinetest/ReadingStep1';
import ListeningStep1 from '../pages/onlinetest/ListeningStep1';
import ReadingStep2 from '../pages/onlinetest/ReadingStep2';
import ListeningStep2 from '../pages/onlinetest/ListeningStep2';
import ReadingStep3 from '../pages/onlinetest/ReadingStep3';
import ListeningStep3 from '../pages/onlinetest/ListeningStep3';

const RouterApp = () => (
  <div>
    <Routes>
      <Route element={<ScrollToTop />}>

        <Route path="/" element={<Main />} />

        <Route element={<DefaultLayout />}>
          <Route path="/exam/online-test/step1/reading" element={<ReadingStep1 />} />
          <Route path="/exam/online-test/step1/listening" element={<ListeningStep1 />} />

          <Route path="/exam/online-test/step2/reading" element={<ReadingStep2 />} />
          <Route path="/exam/online-test/step2/listening" element={<ListeningStep2 />} />

          <Route path="/exam/online-test/step3/reading" element={<ReadingStep3 />} />
          <Route path="/exam/online-test/step3/listening" element={<ListeningStep3 />} />
        </Route>

        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Page404/>}/>
      </Route>
    </Routes>
  </div>
);

export default RouterApp;