import {Col, Form, Row} from "react-bootstrap";
import {Controller} from "react-hook-form";
import ErrorMessageFeedback from "../../components/common/ErrorMessageFeedback";
import { Validator } from "../../utils";

function GeneralInfo({ control, errors }) {

  return (
    <>
      <Row>
        <Col xs="12">
          <Form.Group className="form-group required" controlId="id_name">
            <Form.Label>학생 이름</Form.Label>
            <Controller
              name="name"
              control={control}
              rules={ {required: true} }
              render={({ field }) =>
                <Form.Control
                  {...field}
                  type="text"
                  isInvalid={errors.name}
                />
              }
            />
            <ErrorMessageFeedback error={errors.name} />
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="form-group required" controlId="id_age">
            <Form.Label>학교</Form.Label>
            <Controller
              name="age"
              control={control}
              rules={ {required: true} }
              render={({ field }) =>
                <Form.Control
                  {...field}
                  type="text"
                  isInvalid={errors.age}
                />
              }
            />
            <ErrorMessageFeedback error={errors.age} />
          </Form.Group>
        </Col>
        <Col xs="12">
          <Form.Group className="form-group required" controlId="id_telephone">
            <Form.Label>학년/반/번호</Form.Label>
            <Controller
              name="telephone"
              control={control}
              rules={{
                required: true,
                validate: {
                  telephone: (value) => Validator.validateTelephone(value)
                }
              }}
              render={({ field }) =>
                <Form.Control
                  {...field}
                  type="text"
                  isInvalid={errors.telephone}
                />
              }
            />
            <ErrorMessageFeedback error={errors.telephone} />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
}

export default GeneralInfo;