export const questions = [
  {
    questionType: 100,
    text: 'I. 단어를 듣고 알맞은 그림을 하나만 고르세요.'
  },
  {
    questionType: 1,
    questionNo: 1,
    question: '1. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-1.mp3',
    answers : [
      {imgPath: '/step1/listening/question-1-1.png'},
      {imgPath: '/step1/listening/question-1-2.png'},
      {imgPath: '/step1/listening/question-1-3.png'},
      {imgPath: '/step1/listening/question-1-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 2,
    question: '2. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-2.mp3',
    answers : [
      {imgPath: '/step1/listening/question-2-1.png'},
      {imgPath: '/step1/listening/question-2-2.png'},
      {imgPath: '/step1/listening/question-2-3.png'},
      {imgPath: '/step1/listening/question-2-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 3,
    question: '3. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-3.mp3',
    answers : [
      {imgPath: '/step1/listening/question-3-1.png'},
      {imgPath: '/step1/listening/question-3-2.png'},
      {imgPath: '/step1/listening/question-3-3.png'},
      {imgPath: '/step1/listening/question-3-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 4,
    question: '4. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-4.mp3',
    answers : [
      {imgPath: '/step1/listening/question-4-1.png'},
      {imgPath: '/step1/listening/question-4-2.png'},
      {imgPath: '/step1/listening/question-4-3.png'},
      {imgPath: '/step1/listening/question-4-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 5,
    question: '5. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-5.mp3',
    answers : [
      {imgPath: '/step1/listening/question-5-1.png'},
      {imgPath: '/step1/listening/question-5-2.png'},
      {imgPath: '/step1/listening/question-5-3.png'},
      {imgPath: '/step1/listening/question-5-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 6,
    question: '6. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-6.mp3',
    answers : [
      {imgPath: '/step1/listening/question-6-1.png'},
      {imgPath: '/step1/listening/question-6-2.png'},
      {imgPath: '/step1/listening/question-6-3.png'},
      {imgPath: '/step1/listening/question-6-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 7,
    question: '7. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-7.mp3',
    answers : [
      {imgPath: '/step1/listening/question-7-1.png'},
      {imgPath: '/step1/listening/question-7-2.png'},
      {imgPath: '/step1/listening/question-7-3.png'},
      {imgPath: '/step1/listening/question-7-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 8,
    question: '8. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-8.mp3',
    answers : [
      {imgPath: '/step1/listening/question-8-1.png'},
      {imgPath: '/step1/listening/question-8-2.png'},
      {imgPath: '/step1/listening/question-8-3.png'},
      {imgPath: '/step1/listening/question-8-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 9,
    question: '9. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-9.mp3',
    answers : [
      {imgPath: '/step1/listening/question-9-1.png'},
      {imgPath: '/step1/listening/question-9-2.png'},
      {imgPath: '/step1/listening/question-9-3.png'},
      {imgPath: '/step1/listening/question-9-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 10,
    question: '10. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-10.mp3',
    answers : [
      {imgPath: '/step1/listening/question-10-1.png'},
      {imgPath: '/step1/listening/question-10-2.png'},
      {imgPath: '/step1/listening/question-10-3.png'},
      {imgPath: '/step1/listening/question-10-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 11,
    question: '11. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-11.mp3',
    answers : [
      {imgPath: '/step1/listening/question-11-1.png'},
      {imgPath: '/step1/listening/question-11-2.png'},
      {imgPath: '/step1/listening/question-11-3.png'},
      {imgPath: '/step1/listening/question-11-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 12,
    question: '12. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-12.mp3',
    answers : [
      {imgPath: '/step1/listening/question-12-1.png'},
      {imgPath: '/step1/listening/question-12-2.png'},
      {imgPath: '/step1/listening/question-12-3.png'},
      {imgPath: '/step1/listening/question-12-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 13,
    question: '13. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-13.mp3',
    answers : [
      {imgPath: '/step1/listening/question-13-1.png'},
      {imgPath: '/step1/listening/question-13-2.png'},
      {imgPath: '/step1/listening/question-13-3.png'},
      {imgPath: '/step1/listening/question-13-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 14,
    question: '14. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-14.mp3',
    answers : [
      {imgPath: '/step1/listening/question-14-1.png'},
      {imgPath: '/step1/listening/question-14-2.png'},
      {imgPath: '/step1/listening/question-14-3.png'},
      {imgPath: '/step1/listening/question-14-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 15,
    question: '15. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-15.mp3',
    answers : [
      {imgPath: '/step1/listening/question-15-1.png'},
      {imgPath: '/step1/listening/question-15-2.png'},
      {imgPath: '/step1/listening/question-15-3.png'},
      {imgPath: '/step1/listening/question-15-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 16,
    question: '16. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-16.mp3',
    answers : [
      {imgPath: '/step1/listening/question-16-1.png'},
      {imgPath: '/step1/listening/question-16-2.png'},
      {imgPath: '/step1/listening/question-16-3.png'},
      {imgPath: '/step1/listening/question-16-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 17,
    question: '17. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-17.mp3',
    answers : [
      {imgPath: '/step1/listening/question-17-1.png'},
      {imgPath: '/step1/listening/question-17-2.png'},
      {imgPath: '/step1/listening/question-17-3.png'},
      {imgPath: '/step1/listening/question-17-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 18,
    question: '18. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-18.mp3',
    answers : [
      {imgPath: '/step1/listening/question-18-1.png'},
      {imgPath: '/step1/listening/question-18-2.png'},
      {imgPath: '/step1/listening/question-18-3.png'},
      {imgPath: '/step1/listening/question-18-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 19,
    question: '19. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-19.mp3',
    answers : [
      {imgPath: '/step1/listening/question-19-1.png'},
      {imgPath: '/step1/listening/question-19-2.png'},
      {imgPath: '/step1/listening/question-19-3.png'},
      {imgPath: '/step1/listening/question-19-4.png'},
    ]
  },
  {
    questionType: 1,
    questionNo: 20,
    question: '20. ' ,
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-20.mp3',
    answers : [
      {imgPath: '/step1/listening/question-20-1.png'},
      {imgPath: '/step1/listening/question-20-2.png'},
      {imgPath: '/step1/listening/question-20-3.png'},
      {imgPath: '/step1/listening/question-20-4.png'},
    ]
  },
  {
    questionType: 100,
    text: 'II. 문제를 듣고 그림과 맞는 내용에는 O 를, 틀린 내용에는 X를 고르세요.'
  },
  {
    questionType: 4,
    questionNo: 21,
    question: ' ',
    isRequired: true,
    imagePath: '/step1/listening/question-21-1.png',
    answers : [
      { text: '1', isRequired: true, audioPath: '/step1/listening/audio/question-audio-21-1.mp3', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
      { text: '2', isRequired: true, audioPath: '/step1/listening/audio/question-audio-21-2.mp3', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
      { text: '3', isRequired: true, audioPath: '/step1/listening/audio/question-audio-21-3.mp3', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
      { text: '4', isRequired: true, audioPath: '/step1/listening/audio/question-audio-21-4.mp3', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
      { text: '5', isRequired: true, audioPath: '/step1/listening/audio/question-audio-21-5.mp3', answerKinds: [ {text: 'O'}, {text: 'X'} ] },
    ]
  },
  {
    questionType: 100,
    text: 'III. 문제를 듣고 그림에서 알맞은 사람을 고르세요.'
  },
  {
    questionType: 4,
    questionNo: 22,
    question: 'III-1. 문제를 듣고 그림에서 알맞은 사람을 고르세요.',
    isRequired: true,
    imagePath: '/step1/listening/question-22-1.png',
    answers : [
      { text: '1', isRequired: true, audioPath: '/step1/listening/audio/question-audio-22-1.mp3', answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'} ] },
      { text: '2', isRequired: true, audioPath: '/step1/listening/audio/question-audio-22-2.mp3', answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'} ] },
      { text: '3', isRequired: true, audioPath: '/step1/listening/audio/question-audio-22-3.mp3', answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'} ] },
      { text: '4', isRequired: true, audioPath: '/step1/listening/audio/question-audio-22-4.mp3', answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'} ] },
      { text: '5', isRequired: true, audioPath: '/step1/listening/audio/question-audio-22-5.mp3', answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'} ] },
    ]
  },
  {
    questionType: 4,
    questionNo: 23,
    question: 'III-2. 문제를 듣고 그림에서 알맞은 사람 혹은 사물을 고르세요.',
    isRequired: true,
    imagePath: '/step1/listening/question-23-1.png',
    answers : [
      { text: '6', isRequired: true, audioPath: '/step1/listening/audio/question-audio-23-1.mp3', answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'} ] },
      { text: '7', isRequired: true, audioPath: '/step1/listening/audio/question-audio-23-2.mp3', answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'} ] },
      { text: '8', isRequired: true, audioPath: '/step1/listening/audio/question-audio-23-3.mp3', answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'} ] },
      { text: '9', isRequired: true, audioPath: '/step1/listening/audio/question-audio-23-4.mp3', answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'} ] },
      { text: '10', isRequired: true, audioPath: '/step1/listening/audio/question-audio-23-5.mp3', answerKinds: [ {text: '1'}, {text: '2'}, {text: '3'}, {text: '4'}, {text: '5'}, {text: '6'}, {text: '7'}, {text: '8'}, {text: '9'} ] },
    ]
  },
  {
    questionType: 100,
    text: 'IV. 대화를 듣고 질문에 알맞은 그림을 고르세요.'
  },
  {
    questionType: 2,
    questionNo: 24,
    question: '1. 영화의 내용은 무엇인가요?',
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-24.mp3',
    imagePath: '/step1/listening/question-24.png',
    answers : [
      { text: '1' },
      { text: '2' },
      { text: '3' },
    ]
  },
  {
    questionType: 2,
    questionNo: 25,
    question: '2. 앨리스는 지금 무엇을 하고 있나요?',
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-25.mp3',
    imagePath: '/step1/listening/question-25.png',
    answers : [
      { text: '1' },
      { text: '2' },
      { text: '3' },
    ]
  },
  {
    questionType: 2,
    questionNo: 26,
    question: '3. 샐리는 무엇을 찾고 있나요?',
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-26.mp3',
    imagePath: '/step1/listening/question-26.png',
    answers : [
      { text: '1' },
      { text: '2' },
      { text: '3' },
    ]
  },
  {
    questionType: 2,
    questionNo: 27,
    question: '4. 제니의 어머니는 무엇을 사고 싶나요?',
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-27.mp3',
    imagePath: '/step1/listening/question-27.png',
    answers : [
      { text: '1' },
      { text: '2' },
      { text: '3' },
    ]
  },
  {
    questionType: 2,
    questionNo: 28,
    question: '5. 여자 아이가 먹고 싶은 것은 무엇인가요?',
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-28.mp3',
    imagePath: '/step1/listening/question-28.png',
    answers : [
      { text: '1' },
      { text: '2' },
      { text: '3' },
    ]
  },
  {
    questionType: 2,
    questionNo: 29,
    question: '6. 어떤 그림이 린다의 강아지인가요?',
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-29.mp3',
    imagePath: '/step1/listening/question-29.png',
    answers : [
      { text: '1' },
      { text: '2' },
      { text: '3' },
    ]
  },
  {
    questionType: 2,
    questionNo: 30,
    question: '7. 할머니는 지금 어디에 있나요?',
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-30.mp3',
    imagePath: '/step1/listening/question-30.png',
    answers : [
      { text: '1' },
      { text: '2' },
      { text: '3' },
    ]
  },
  {
    questionType: 2,
    questionNo: 31,
    question: '8. 루시가 입고 있는 옷은 무엇일까요?',
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-31.mp3',
    imagePath: '/step1/listening/question-31.png',
    answers : [
      { text: '1' },
      { text: '2' },
      { text: '3' },
    ]
  },
  {
    questionType: 2,
    questionNo: 32,
    question: '9. 악어는 무얼 하는 것을 좋아하나요?',
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-32.mp3',
    imagePath: '/step1/listening/question-32.png',
    answers : [
      { text: '1' },
      { text: '2' },
      { text: '3' },
    ]
  },
  {
    questionType: 2,
    questionNo: 33,
    question: '10. 엄마가 가장 좋아하는 과일은 무엇인가요?',
    isRequired: true,
    audioPath: '/step1/listening/audio/question-audio-33.mp3',
    imagePath: '/step1/listening/question-33.png',
    answers : [
      { text: '1' },
      { text: '2' },
      { text: '3' },
    ]
  },
];