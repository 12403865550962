import { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from 'react-query';
import HelmetWrapper from "../../../components/common/HelmetWrapper";
import { http } from "../../../utils";
import { questions } from '../../../data/step3/listening';
import GeneralInfo from '../GeneralInfo';
import QuestionType1 from '../../questionType/QuestionType1';
import QuestionType2 from '../../questionType/QuestionType2';
import QuestionType3 from '../../questionType/QuestionType3';
import QuestionType100 from "../../questionType/QuestionType100";
import QuestionType4 from "../../questionType/QuestionType4";

const stepInfo = {
  progress : 0,
  complete : 1
}

function ListeningStep2() {
  const initValue = useMemo(() => {
    return {
      name: '',
      age: '',
      telephone: ''
    }
  }, []);
  const [testStep, setTestStep] = useState(stepInfo.progress);
  const {handleSubmit, reset, formState: {errors}, control } = useForm({
    defaultValues : { ...initValue }
  });
  const saveAction = useMutation((params) => http.post('/api/v1/exam/online-test/step3/listening', params));

  const onValid = async (data) => {
    await saveAction.mutateAsync(data);
    setTestStep(stepInfo.complete);
  }

  return (
    <>
      <HelmetWrapper meta={{
        title : 'Listening'
      }} />

      {
        testStep === stepInfo.progress &&
        <>
          <div className="content-header mb-3">
            <h3>서울대 온라인 테스트</h3>
            <div className="mb-3">Listening Comprehension</div>
            <div style={{color: "#d93025"}}>* 필수항목</div>
          </div>

          <Form onSubmit={handleSubmit(onValid)} onReset={reset} className="w-100">
            <div className="content-block">
              <GeneralInfo control={control} errors={errors} />
            </div>

            {
              questions.map((question, idx) => {
                if(question.questionType === 1) {
                  return <QuestionType1 key={idx} questionNo={question.questionNo} item={question} control={control} errors={errors} />
                }
                if(question.questionType === 2) {
                  return <QuestionType2 key={idx} questionNo={question.questionNo} item={question} control={control} errors={errors} />
                }
                if(question.questionType === 3) {
                  return <QuestionType3 key={idx} questionNo={question.questionNo} item={question} control={control} errors={errors} />
                }
                if(question.questionType === 4) {
                  return <QuestionType4 key={idx} questionNo={question.questionNo} item={question} control={control} errors={errors} />
                }
                if(question.questionType === 100) {
                  return <QuestionType100 key={idx} item={question} />
                }
                return <></>;
              })
            }

            <div className="btn-wrapper mt-5">
              <button className="btn btn-primary" type="submit">
                <span className="material-icons">done</span> 제출하기
              </button>
            </div>
          </Form>
        </>
      }
      {
        testStep === stepInfo.complete &&
        <>
          <div className="content-header mb-3">
            <h3>서울대 온라인 테스트</h3>
            <div className="mb-3">Listening Comprehension</div>
            <div className="mt-4"> 테스트가 끝났어요.  수고하셨어요!</div>
          </div>
        </>
      }
    </>
  );
}

export default ListeningStep2;

