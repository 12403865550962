const Header = () => {
  return (
    <header className="header mt-3 mb-3 col-12 col-md-8 col-lg-6">
      <div className="header-fluid">
        {/*<div className="system-title">*/}
        {/*  <a href="http://www.archipin.com" target="_blank" rel="noreferrer"><img className="logo" src="/logo.png" alt="logo" /></a>*/}
        {/*</div>*/}
        <div className="user-info">
        </div>
      </div>
    </header>
  );
};

export default Header;
